import styled from 'styled-components'
import tw from 'twin.macro'

const BottomSection = styled.div`
  ${tw`
    pt-6
    pb-8
    px-6
  `}
`

const Button = styled.button`
  ${tw`
    mt-8
    w-full
    h-auto
    flex
    items-center
    justify-center
    bg-sr-600
    rounded-md
    py-2
    text-sm
    leading-5
    font-semibold
    text-white
    transition
    duration-150
    ease-in-out

    hover:bg-sr-700

    focus:outline-none
    focus:ring-gray-500
  `}
`

const Container = styled.div`
  ${tw`
    border
    border-gray-100
    rounded-md
    shadow-md
    divide-y
    divide-gray-200
  `}
`

const Description = styled.p`
  ${tw`
    mt-4
    leading-5
    text-gray-500
  `}
`

const Feature = styled.li`
  ${tw`
    flex
    space-x-3
  `}
`

const Features = styled.ul`
  ${tw`
    mt-1
    space-y-6
  `}
`

const FeaturesHeader = styled.h3`
  ${tw`

  `}
`

const FeatureText = styled.span`
  ${tw`
    leading-5
    text-gray-600
  `}
`

const Header = styled.h2`
  ${tw`
    text-xl
    leading-6
    font-medium
    text-gray-900
  `}
`

const PerMonth = styled.span`
  ${tw`
    text-base
    leading-6
    font-medium
    text-gray-500
  `}
`

const Price = styled.span`
  ${tw`
    text-4xl
    leading-10
    font-extrabold
    text-gray-900
  `}
`

const PriceContainer = styled.p`
  ${tw`
    mt-8
  `}
`

const TopSection = styled.div`
  ${tw`
    p-6
  `}
`

export {
  BottomSection,
  Button,
  Container,
  Description,
  Feature,
  Features,
  FeaturesHeader,
  FeatureText,
  PerMonth,
  Header,
  Price,
  PriceContainer,
  TopSection,
}
