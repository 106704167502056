import styled from 'styled-components'

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.07), 0px 0px 6px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  width: 100%;
`

export { Container }
