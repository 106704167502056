import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

import { backendBaseUrl, isDev } from '$/config'
import type { Optional, User } from '$/types'

import { getDefaultConfig } from './backend'

const stripePromise = loadStripe(
  isDev
    ? 'pk_test_L4yOYpqvt22qder0PKxx44VV'
    : 'pk_live_dFwBtLnAM0IpzpA7FTMUcBgq',
)

export const redirectToCheckout = async (
  user: User,
  price: string,
  customer: Optional<string>,
): Promise<void> => {
  const sessionId = await getCheckoutSession(user, price, customer)

  return redirectToCheckoutUsingSessionsId(sessionId)
}

export const redirectToStripePortal = async (
  user: User,
  customer: Optional<string>,
): Promise<void> => {
  const returnUrl = `${window.location.origin}/pricing`
  const portalSessionGeneratorUrl = `${backendBaseUrl}/redirectToStripePortal`
  const config = await getDefaultConfig(user)

  const { data } = await axios.post(
    portalSessionGeneratorUrl,
    {
      cid: customer,
      rurl: window.location.origin,
      cancelUrl: returnUrl,
    },
    config,
  )

  window.location = data.url
}

export const changeSubscription = async (
  user: User,
  teamId: Optional<string>,
  newPrice: string,
): Promise<void> => {
  const changeSubscripionUrl = `${backendBaseUrl}/updateStripeSubscription`
  const config = await getDefaultConfig(user)

  await axios.post(
    changeSubscripionUrl,
    {
      teamId,
      subscriptions: [newPrice],
    },
    config,
  )
}

const getCheckoutSession = async (
  user: User,
  price: string,
  customer: Optional<string>,
) => {
  const checkoutSessionGeneratorUrl = `${backendBaseUrl}/generateStripeCheckoutSession`

  const returnUrl = `${window.location.origin}/pricing`
  const config = await getDefaultConfig(user)

  const { data } = await axios.post(
    checkoutSessionGeneratorUrl,
    {
      price,
      email: user?.email,
      customer,
      successUrl: returnUrl,
      cancelUrl: returnUrl,
    },
    config,
  )

  return data
}

const redirectToCheckoutUsingSessionsId = async (session: { id: string }) => {
  const stripe = await stripePromise

  try {
    stripe!.redirectToCheckout({ sessionId: session.id })
  } catch (e) {
    console.error(e)
  }
}
