import React from 'react'

import { areAdBlockersOff } from '$/utils/adblockers'
import { useUserState } from '$/hooks'
import type { User } from '$/types'
import { plans, supportEmail } from '$/config'

import PricingCard from './PricingCard/PricingCard'

const Pricing: React.FC = () => {
  const user = useUserState()

  const activePlans = () =>
    Object.entries(plans)
      .map(([, plan]) => plan)
      .filter((plan) => plan.active)

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-500 sm:text-center">
            Subscription options that scale with your business
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:grid-cols-2 mx-auto">
          {activePlans().map((plan, index) => (
            <PricingCard key={`card-${index.toString()}`} plan={plan} />
          ))}
        </div>

        <div className="border border-gray-100 rounded-md shadow-md p-4 mt-8 lg:max-w-4xl flex space-x-8 items-center mx-auto">
          <div className="flex-1">
            <h1 className="text-gray-800 text-md font-medium">
              Need something more?
            </h1>
            <p className="text-md text-gray-500 mt-2">
              Get a custom plan for your company with unlimited drivers,
              recipient notifications, and more
            </p>
          </div>
          <button
            type="button"
            onClick={() => handleContactClick(user)}
            className="w-auto h-10 shadow-none px-6 border rounded-md border-indigo-600 hover:bg-indigo600 hover:border-none hover:text-white bg-transparent py-2 text-sm leading-5 font-bold text-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-gray-500 transition duration-150 ease-in-out"
          >
            Get in touch
          </button>
        </div>
      </div>
    </div>
  )
}

const handleContactClick = (user: User) => {
  // eslint-disable-next-line no-constant-condition
  areAdBlockersOff() && false
    ? openIntercomWindow(user)
    : (window.location.href = `mailto:${supportEmail}`)
}

const openIntercomWindow = (_user: User) => {
  // tbd
}

export default Pricing
