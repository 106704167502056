import React, { useEffect, useState } from 'react'

import { useSubscriptionState, useUserState, useDispatch } from '$/hooks'
import type { PlanData } from '$/types'
import { isSubscribedToPlan, hasValidSubscription } from '$/utils/subscription'
import { track } from '$/utils/amplitude'

import {
  changeSubscription,
  redirectToCheckout,
  redirectToStripePortal,
} from '../../../api/stripe'
import { LoadingIndicator } from '../../Util/LoadingIndicator'
import {
  BottomSection,
  Button,
  Container,
  Description,
  Feature,
  Features,
  FeaturesHeader,
  FeatureText,
  Header,
  PerMonth,
  Price,
  PriceContainer,
  TopSection,
} from './PricingCard.styled'
import { MapPinSvg, UserSvg } from '../../shared/svgs'

interface PricingCardProps {
  children?: React.ReactNode
  key: string
  plan: PlanData
}

const PricingCard: React.FC<PricingCardProps> = ({
  plan,
}: PricingCardProps) => {
  const subscription = useSubscriptionState()
  const user = useUserState()
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(false)
  }, [subscription])

  const [loading, setLoading] = useState(false)

  const getButtonLabel = () =>
    isSubscribedToPlan(plan, subscription)
      ? 'Unsubscribe'
      : hasValidSubscription(subscription)
      ? 'Change subscription'
      : 'Subscribe'

  const handlePlanClick = async (stripePriceId: string) => {
    if (isSubscribedToPlan(plan, subscription)) {
      track('Cancel plan clicked', { Plan: plan.title })
      await redirectToStripePortal(
        user,
        subscription ? subscription.stripeCustomerId : null,
      )
    } else if (subscription.stripeProductIds.size > 0) {
      track('Change plan clicked', { Plan: plan.title })
      await changeSubscription(user, subscription.teamId, stripePriceId)
    } else {
      track('Subscribe clicked', { Plan: plan.title })

      await redirectToCheckout(
        user,
        stripePriceId,
        subscription ? subscription.stripeCustomerId : null,
      )
    }
  }

  const driverLabel = `${plan.features.maxDrivers.toString()} ${
    plan.features.maxDrivers > 1 ? 'drivers' : 'driver'
  }`

  const stopsLabel =
    plan.features.maxStops === -1 ? 'Unlimited' : plan.features.maxStops

  const handleClick = () => {
    if (user?.isAnonymous === false) {
      setLoading(true)
      handlePlanClick(plan.stripePriceId)
    } else {
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'createAccount' })
    }
  }

  return (
    <Container>
      <TopSection>
        <Header>{plan.title}</Header>
        <Description>{plan.description}</Description>
        <PriceContainer>
          <Price>${plan.price}</Price>
          <PerMonth>/mo</PerMonth>
        </PriceContainer>
        <Button onClick={handleClick}>
          <LoadingIndicator visible={loading} />
          <p className={loading ? 'hidden' : ''}> </p>
          {getButtonLabel()}
        </Button>
      </TopSection>

      <BottomSection>
        <Features>
          <FeaturesHeader>Create routes with</FeaturesHeader>
          <Feature>
            <UserSvg />
            <FeatureText>{driverLabel}</FeatureText>
          </Feature>
          <Feature>
            <MapPinSvg />
            <FeatureText>{stopsLabel} stops</FeatureText>
          </Feature>
        </Features>
      </BottomSection>
    </Container>
  )
}

export default PricingCard
