import React from 'react'

import Pricing from '$/components/Pricing/Pricing'
import { Container } from '$/theme/pages/pricing.styled'

const Index: React.FC = () => (
  <Container>
    <Pricing />
  </Container>
)

export default Index
